import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import NewInformationListItem from "../components/parts/NewInformationListItem";
import PageTitle from "../components/parts/PageTitile";
import PankuzuList from "../components/parts/PankuzuList";
import { Seo } from "../components/seo";
import { newsListData } from "../data/newsListData";

const NewInformation = ({ location }) => {
  return (
    <Layout>
      <PageTitle title="新着情報" subTitle="New Information" />
      <PankuzuList title="新着情報一覧" location={location} />
      <StyledNewInformation>
        <ul>
          {newsListData
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((item, index) => (
              <NewInformationListItem key={item.text + index} item={item} />
            ))}
        </ul>
      </StyledNewInformation>
    </Layout>
  );
};
const StyledNewInformation = styled.div`
  width: 90%;
  max-width: var(--max-width);
  margin: 0 auto 10rem;
`;
export default NewInformation;

export const Head = () => <Seo title="新着情報"></Seo>;
