export const newsListData = [
  // {
  //   date: "2021/10/30",
  //   text: "宝庵茶屋にて和菓子をご提供いたします。",
  //   link: "https://www.houan1934.com/chaya",
  // },
  {
    date: "2022/10/18",
    text: "「名茶室を味わう」夢窓庵の茶席付にて和菓子をご提供いたします",
    link: "https://www.houan1934.com/guide",
  },
  {
    date: "2022/10/22",
    text: "「名茶室を味わう」夢窓庵の茶席付にて和菓子をご提供いたします",
    link: "https://www.houan1934.com/guide",
  },
  {
    date: "2022/09/24",
    text: "宝庵茶屋にて和菓子をご提供いたします。",
    link: "",
  },
  {
    date: "2022/10/10",
    text: "茶会体験会にて和菓子をご提供いたします。",
    link: "",
  },
];
