import React from "react";
import styled from "styled-components";
import Device from "../media/Device";

const NewInformationListItem = ({ item }) => {
  const dateForTime = item.date.replaceAll("/", "-");
  const dateForText = item.date.replaceAll("/", ".");
  return (
    <StyledLi>
      <time dateTime={dateForTime} className="update__date">
        {dateForText}
      </time>
      <span className="update__text">{item.text}</span>
    </StyledLi>
  );
};

const StyledLi = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed var(--gray-color);
  padding: 2.5rem 0;

  @media ${Device.tablet} {
    flex-direction: row;
    padding: 3rem 0;
  }
  .update {
    &__date,
    &__text {
      color: var(--black-color);
      line-height: 2.4rem;
      letter-spacing: var(--ls-normal);
    }
    &__date {
      color: var(--sub-color);
      margin-right: 2rem;
      margin-bottom: 0.8rem;

      @media ${Device.tablet} {
        margin-bottom: 0;
      }
    }
  }
`;
export default NewInformationListItem;
